import React, {useState} from 'react'
import Aboutus from '../components/Aboutus';
import Client from '../components/Client';
import Footer from '../components/Footer';
import Hero from '../components/Hero'
import Info from '../components/Info'
import {homeObjThree} from '../components/Info/Data';
import Navbar from '../components/Navbar';
import Services from '../components/Services';
import Sidebar from '../components/SideBar';
import Experience from '../components/Experience';
import Produk from '../components/Produk';
import Solution from '../components/Solution';
import Privacy from '../components/Privacy';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle}/>
      <Hero />
      <Aboutus/>
      <Produk />
      <Solution />
      <Services />
      <Client/>
      <Experience />
      <Privacy />
      <Info {...homeObjThree} />
      <Footer />
    </>
  )
}

export default Home
