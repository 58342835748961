import React from 'react';
import { Clientcontainer, ClientH1, Clientwrapper, Clientbrand, Clientimg } from './clientelement';
import Clientone from '../../images/clients/1.png';
import Clienttwo from '../../images/clients/2.png';
import Clientthree from '../../images/clients/3.png';
import Clientfour from '../../images/clients/4.png';
import Clientfive from '../../images/clients/5.png';
import Clientsix from '../../images/clients/6.png';


const client = () => {
    return (
        <Clientcontainer id='client'>
            <ClientH1>
                Our Client
            </ClientH1>
            <Clientwrapper>
                <Clientbrand>
                    <Clientimg src={Clientone}/>
                </Clientbrand>
                <Clientbrand>
                    <Clientimg src={Clienttwo}/>
                </Clientbrand>
                <Clientbrand>
                    <Clientimg src={Clientthree}/>
                </Clientbrand>
                <Clientbrand>
                    <Clientimg src={Clientfour}/>
                </Clientbrand>
                <Clientbrand>
                    <Clientimg src={Clientfive}/>
                </Clientbrand>
                <Clientbrand>
                    <Clientimg src={Clientsix}/>
                </Clientbrand>
            </Clientwrapper>
        </Clientcontainer>
    )
}

export default client
