import React from "react";
import Carousel from "react-elastic-carousel";
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP}  from './solutionelement';
import Software from '../../images/solution/software.png'
import Network from '../../images/solution/network.png'
import Intelligence from '../../images/solution/intelligence.png'
import Data from '../../images/solution/data.png'
import Colaboration from '../../images/solution/colaboration.png'
import IOT from '../../images/solution/iot.png'
import AI from '../../images/solution/ai.png'
import Bigdata from '../../images/solution/bigdata.png'
// import Solution5 from '../../images/solution/solut   ion5.png'
import Item from "./item";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Solution = () => {
    return (
        <ServicesContainer id='solution'>
            <ServicesH1>
                Solution
            </ServicesH1>
        <Carousel breakPoints={breakPoints}>

        <ServicesCard>
          <ServicesIcon src={Software}/>
          <ServicesH2>ENTERPRISE SOFTWARE SOLUTIONS</ServicesH2>
          <ServicesP>Information overload is the new scourge in the IT world as everyone generates</ServicesP>
        </ServicesCard>
        <ServicesCard>
        <ServicesIcon src={Network}/>
          <ServicesH2>NETWORK & INFRASTRUCTURE</ServicesH2>
          <ServicesP>Kuantum is a leading system integrator that provides network infrastructure solutions to consolidate and optimize IT resources</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Intelligence}/>
          <ServicesH2>INTELLIGENT SECURITY SOLUTIONS</ServicesH2>
          <ServicesP>Intelligence Security Solution is the collection of technologies, standards, policies and management practices...</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Data}/>
          <ServicesH2>DATA CENTER & VIRTUALIZATION</ServicesH2>
          <ServicesP>IT operations are a crucial aspect of most organizational operations in the world....</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Colaboration}/>
          <ServicesH2>COMMUNICATION & COLLABORATION</ServicesH2>
          <ServicesP>Kuantum provides communication and collaboration solutions in.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={IOT}/>
          <ServicesH2>INTERNET OF THINGS (IOT) SOLUTION</ServicesH2>
          <ServicesP>IoT opens the door for new business opportunities and helps companies benefit with new revenue streams...</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={AI}/>
          <ServicesH2>ARTIFICIAL INTELLIGENCE (AI) SOLUTION</ServicesH2>
          <ServicesP>The recent development and successes of enterprise analytics is heavily influenced by the growth of Artificial Intelligence...</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Bigdata}/>
          <ServicesH2>BIG DATA ANALYTIC</ServicesH2>
          <ServicesP>Capable of recognizing faces, objects, limited/emotional, detecting the same person at various angles and can be used during the day and night.</ServicesP>
        </ServicesCard>
      
            </Carousel>
        </ServicesContainer>
    )
}

export default Solution;