export const homeObjThree = {
  id: "signup",
  lightBg: false,
  lightText: false,
  lighttextDesc: true,
  topLine: "",
  headline: "What are you waiting for?",
  description: "",
  buttonLabel: "Start Now",
  imgStart: false,
  img: "/images/svg-4.svg",
  alt: "car",
  dark: true,
  primary: false,
  darkText: true,
};
