import React from 'react';
import {AboutContainer, Aboutwrapper, Columnone, Columntwo, Imgwrap, Textwrapper, Textheading, Subtext, Buttonwrap, Imgabout, AboutRow} from './aboutuselement';
import Img from '../../images/svg-5.svg';
import { Button } from '../ButtonElements';

const Aboutus = ({imgStart}) => {
    return (
        <AboutContainer id='about'>
            <Aboutwrapper>
              <AboutRow imgStart={imgStart}>
                  <Columnone>
                      <Imgwrap>
                          <Imgabout src={Img} alt=''/>
                      </Imgwrap>
                  </Columnone>
                  <Columntwo>
                    <Textwrapper>
                        <Textheading>Why choose us?</Textheading>
                        <Subtext>
                        We give our service of excellent to our valued clients and a competitive advantage & integrated web solution packages as being secured by best class of web to your company.
                        </Subtext>
                        <Buttonwrap>
                            <Button to='About'>More about us</Button>
                        </Buttonwrap>
                    </Textwrapper>  
                  </Columntwo>
              </AboutRow>
            </Aboutwrapper>
        </AboutContainer>
    )
}

export default Aboutus
