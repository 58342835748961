import styled from 'styled-components';
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'

export const HeroContainer = styled.div`
  background: #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 800px;
  position: relative;
  z-index: 1;
`
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`
export const ImgBg = styled.img`
  background: #fefefe;
  display: flex;
  width: auto;
  height: 100%;
`
export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const HeroH1 = styled.h1`
  color: #101606;
  font-size: 48px;
  font-weight: 800;
  text-align: center;

  @media screen and (max-wodth: 768px) {
    font-size: 40px
  }
  @media screen and (max-wodth: 480px) {
    font-size: 28px
  }
`

export const HeroP = styled.p`
  margin-top: 12px;
  color: #454545;
  font-size: 24px;
  text-align: center;
  font-weight: 400;
  max-width: 600px;

  @media screen and (max-wodth: 768px) {
    font-size: 24px
  }
  @media screen and (max-wodth: 480px) {
    font-size: 16px
  }
`
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`

export const Logofew = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
`


export const Logoimg = styled.img`
  height: 64px;
  width: 64px;
  filter: grayscale(100%);
  opacity: 0.65;
  align-items: center;
  margin: 18px;
`