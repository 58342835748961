import React from 'react'
import {  FaPhone, FaPaperPlane, FaMapMarked } from 'react-icons/fa';
import { FooterContainer, FooterWrap, FooterLinkWrapper, FooterLinkItems, FooterLinkContainer, FooterLinkTitle, FooterLink, SocialLogo, SocialMedia, SocialMediaWrap, WebsiteRights,Socialimg } from './FooterElements'
import { animateScroll as scroll } from 'react-scroll';
import logo from '../../images/logo-white.png';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinkContainer>
          <FooterLinkWrapper>
            <FooterLinkItems>
            <SocialLogo to='/' onClick={toggleHome}>
              <Socialimg src={logo} alt='logo'/>
            </SocialLogo>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>About us</FooterLinkTitle>
                <FooterLink to='/'>Carrers</FooterLink>
                <FooterLink to='/'>Terms of Service</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
                
                <FooterLink href="https://www.google.com/maps/place/PT.+Kuantum+Solusi+Teknologi/@-6.2916153,106.803461,15z/data=!4m2!3m1!1s0x0:0x9d7267bd6742b1ab?sa=X&ved=2ahUKEwiGx-jfp4vyAhViILcAHbD9A5kQ_BIwE3oECEcQBQ">   <p><FaMapMarked/> Menara 165,<br/> Jl. TB Simatupang Kav. 1
Kel. Cilandak Timur Kec. Pasar Minggu
Jakarta Selatan - 12560
Lt. 2 Platinum no. 9</p> 
                </FooterLink>
                <FooterLink href="tel:+622175999899"><FaPhone><br/><br/></FaPhone>+6221 75999899</FooterLink>
                <FooterLink href="mailto:info@kuantumsolusi.com"><FaPaperPlane/> info@kuantumsolusi.com</FooterLink>
            </FooterLinkItems>
          </FooterLinkWrapper>

        </FooterLinkContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <WebsiteRights>Kuantum Solusi Teknologi © {new Date().getFullYear()} All rights reserved.</WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
