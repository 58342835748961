import styled from 'styled-components';

export const AboutContainer  = styled.div`
 color: #fff;
 background: #fefefe;
 position: relative;
 @media screen and  (max-width: 768px) {
    padding: 100px 0;
  }
`
export const Aboutwrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 680px;
  width: 100% auto;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
`
export const AboutRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}
    }
`
export const Columnone = styled.div`
    margin-bottom: 15px;
    padding: 0 16px;
    grid-area: col2;
`

export const Columntwo = styled.div`
  margin-bottom: 15px;
  padding: 0 16px;
  grid-area: col1;
`

export const Textwrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
    `

export const Textheading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600px;
    color: #101606;

    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
`

export const Subtext = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: #101606;
`

export const Buttonwrap = styled.div`
display:flex;
justify-content: flex-start;
`

export const Imgwrap = styled.div`
max-width: 480px;
height: 100%;
`

export const Imgabout = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`
