import React, {useState} from 'react'
// import Video from '../../videos/video.mp4'
import { HeroContainer, HeroBg, ImgBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight, Logofew, Logoimg } from './HeroElements'
import { Button } from '../ButtonElements'
import Pattern from '../../videos/Strip-Patterns.png';
import Clientone from '../../images/clients/1.png';
import Clienttwo from '../../images/clients/5.png';
import Clientthree from '../../images/clients/3.png';
import Clientfour from '../../images/clients/4.png';

const Hero = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
      <HeroBg>
        <ImgBg src={Pattern} alt=''/>
        {/* <VideoBg autoPlay loop muted src={Video} type='video/mp4' /> */}
      </HeroBg>
      <HeroContent>
        <HeroH1>Kuantum Solusi Teknologi</HeroH1>
        <HeroP>a problem solver, professional, innovative and efficient web solution for your company</HeroP>
        <HeroBtnWrapper>
          <Button to='signup' onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'               smooth={true} duration={500} spy={true} exact='true' offset={-80}>
            Get Started {hover ? <ArrowForward /> : <ArrowRight/>}
          </Button>
        </HeroBtnWrapper>
        <Logofew>
          <Logoimg src={Clientone} alt=''/>
          <Logoimg src={Clienttwo} alt=''/>
          <Logoimg src={Clientthree} alt=''/>
          <Logoimg src={Clientfour} alt=''/>
        </Logofew>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
