import styled from 'styled-components';


export const Clientcontainer = styled.div`
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
 `

export const Clientwrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
  }

    @media screen and (max-width: 786px) {
      grid-template-columns: 1fr 1fr;
      padding: 0 20px;
  }

      @media screen and (max-width: 486px) {
      grid-template-columns: 1fr 1fr;
      padding: 0 100px;
  }
`

export const Clientbrand = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding:  30px;
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.08);
    transition: all 0.3s ease-in-out;
    cursor:pointer;
    filter: grayscale(0%);
  }
 `

export const Clientimg = styled.img`
    height: 160px;
    width: 160px;
    align-items: center;
    
` 

export const ClientH1 = styled.h1`
  font-size: 2.5rem;
  color: #010606;
 
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    margin-top: auto;
    font-size: 2rem;
  }  
`

