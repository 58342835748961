import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(108deg, #283d84 0%, #5c87c3 100%);
`;
export const FormWrap = styled.div`
  height: 100% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    height: 80%;
  }
`;
export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  font-size: 32px;

  @media screen and (max-width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;
export const Navimg = styled.img`
  max-height: 55px;
  position: relative;
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;
export const Form = styled.form`
  background-color: #fefefe;
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 80px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 480px) {
    padding: 32px 32px;
  }
`;
export const FormH1 = styled.h1`
  margin-bottom: 40px;
  color: #101606;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;
export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #101606;
`;
export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border-radius: 4px;
`;
export const FormInput1 = styled.input`
  padding: 16px 16px;
  margin-bottom: 16px;
  border-radius: 4px;
`;

export const FormButton = styled.button`
  background: #283d84;
  padding: 16px 0;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

export const FooterLink = styled(Link)`
  text-align: justify;
  color: #000;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #5C87C3;
    transition: 0.3s ease-in-out;
  }
`;

export const FooterLink1 = styled(Link)`
  text-align: justify;
  color: #000;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  margin-top: 7px;
  &:hover {
    color: #5C87C3;
    transition: 0.3s ease-in-out;
  }
`;

export const Image = styled.img`
  max-height: 38px;
  position: relative;
  margin-right: 10px;
  float: left;
`;

export const Image1 = styled.img`
  max-height: 28px;
  position: relative;
  margin-right: 17px;
  float: left;
`;