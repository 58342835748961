import React from "react";
import Carousel from "react-elastic-carousel";
import {ProdukContainer, ProdukH1, ProdukWrapper, ProdukCard, ProdukIcon, ProdukIcon2, ProdukH2,ProdukH3, ProdukP, NavBtn, NavBtnLink}  from './produkElement';
import Server1 from '../../images/produk/server1.png'
import Produk1 from '../../images/produk/server1.pdf'
import Produk2 from '../../images/produk/server2.pdf'
import Produk3 from '../../images/produk/server3.pdf'
import Produk4 from '../../images/produk/server4.pdf'
import Produk5 from '../../images/produk/server5.pdf'
import { Button } from '../ButtonElements'
import Item from "./item";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Experience = () => {
    return (
        <ProdukContainer id='produk'>
            <ProdukH1>
                Products
            </ProdukH1>
        <Carousel breakPoints={breakPoints}>

        <ProdukCard>
          <ProdukIcon src={Server1}/>
                    <ProdukH2>OMNI SM64C128G</ProdukH2>
                    <ProdukH3>Description</ProdukH3>
          <ProdukP>Source Code Management Appliance</ProdukP>
        <NavBtn>
            <NavBtnLink to={Produk1} target="_blank">View Detail</NavBtnLink>
          </NavBtn>
        </ProdukCard>
        
        <ProdukCard>
          <ProdukIcon2 src={Server1}/>
            <ProdukH2>OMNI OM32C64G</ProdukH2>
            <ProdukH3>Description</ProdukH3>
          <ProdukP>One Management Appliance</ProdukP>
                    <NavBtnLink to={Produk2} target="_blank">View Detail</NavBtnLink>
                </ProdukCard>
                
        <ProdukCard>
          <ProdukIcon2 src={Server1}/>
            <ProdukH2>OMNI MA32C64G</ProdukH2>
            <ProdukH3>Description</ProdukH3>
          <ProdukP>Monitoring, Logging, Automation Server Appliance</ProdukP>
        <NavBtnLink to={Produk3} target="_blank">View Detail</NavBtnLink>
        </ProdukCard>

        <ProdukCard>
          <ProdukIcon src={Server1}/>
            <ProdukH2>OMNI VS32C64G</ProdukH2>
            <ProdukH3>Description</ProdukH3>
                    <ProdukP>Host Vulnerability Assestment Scanner Appliance</ProdukP>
                    <NavBtnLink to={Produk4} target="_blank">View Detail</NavBtnLink>
        </ProdukCard>
        <ProdukCard>
          <ProdukIcon src={Server1}/>
            <ProdukH2>OMNI AM64C128G</ProdukH2>
            <ProdukH3>Description</ProdukH3>
                    <ProdukP>Application Performance Monitoring Appliance</ProdukP>
                    <NavBtnLink to={Produk5} target="_blank">View Detail</NavBtnLink>
        </ProdukCard>
      
            </Carousel>
        </ProdukContainer>
    )
}

export default Experience;