import React from "react";
import { Container, FormWrap, Icon, FormContent, Form, FormInput, FormH1, FormLabel, FormButton, Navimg, Image,Image1, FooterLink, FooterLink1 } from "./Contactuselements";
import logo from "../images/logo-white.png";
import email from "../images/email1.png";
import map from "../images/map.png";
import phone from "../images/phone.png";
import {  FaPhone,FaPaperPlane, FaMapMarked} from 'react-icons/fa';

const Contactus = () => {
  return (
    <>
      <Container>
        <FormWrap>
          <Icon to="/">
            <Navimg src={logo} alt="logo" />
          </Icon>
          <FormContent>
            <Form action="#">
              <FormH1>Contact Us</FormH1>
              <FooterLink href="https://www.google.com/maps/place/PT.+Kuantum+Solusi+Teknologi/@-6.2916153,106.803461,15z/data=!4m2!3m1!1s0x0:0x9d7267bd6742b1ab?sa=X&ved=2ahUKEwiGx-jfp4vyAhViILcAHbD9A5kQ_BIwE3oECEcQBQ"> <Image src={map} alt="logo" />  <p> Menara 165, Jl. TB Simatupang Kav. 1
                    Kel. Cilandak Timur Kec. Pasar Minggu
                    Jakarta Selatan - 12560
                    Lt. 2 Platinum no. 9</p> 
                </FooterLink>
              <FooterLink1 href="tel:+622175999899"> <Image1 src={phone} alt="logo" /> <p style={{ paddingTop: "5px"}}> +6221 75999899 </p></FooterLink1>
                <FooterLink1 href="mailto:info@kuantumsolusi.com"><Image1 src={email} alt="logo" /><p style={{ paddingTop: "5px"}}> info@kuantumsolusi.com </p></FooterLink1>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
};

export default Contactus;
