import styled from 'styled-components';
import { Link } from "react-router-dom";

export const PrivacyContainer  = styled.div`
   height: 320px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
`
export const Privacywrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100% auto;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
`
export const PrivacyRow = styled.div`
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
`
export const Columnone = styled.div`
    margin-bottom: 15px;
    padding: 0 16px;
    grid-area: col2;
`

export const Columntwo = styled.div`
  margin-bottom: 15px;
  padding: 10px 16px;
`

export const Textwrapper = styled.div`
    padding-top: 0;
    `

export const Textheading = styled.h1`
    margin-bottom: 14px;
    font-size: 17px;
    line-height: 0.1;
    font-weight: 600px;
    color: #101606;

    @media screen and (max-width: 480px) {
        font-size: 28px;
    }
`

export const Subtext = styled.p`
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
    color: #101606;
    text-align: justify;
`

export const Buttonwrap = styled.div`
display:flex;
justify-content: flex-start;
`

export const Imgwrap = styled.div`
max-width: 480px;
height: 100%;
`

export const ImgPrivacy = styled.img`
width: 100%;
margin: 0 0 10px 0;
padding-right: 0;
`

export const PrivacyH1 = styled.h1`
  margin-top: 20px;
  font-size: 2.5rem;
  color: #222222;
  margin-bottom: 64px;
  line-height: 2;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
    line-height: 2;
  }  
`

export const FooterLink1 = styled(Link)`
  text-align: justify;
  color: #5C87C3;
  text-decoration: none;
  display: inline-block;
  margin-left: 8px;
  font-size: 16px;
  &:hover {
    color: #5C87C3;
    transition: 0.3s ease-in-out;
  }
`;

export const FooterLink = styled(Link)`
  text-align: justify;
  color: #000;
  text-decoration: none;
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #5C87C3;
    transition: 0.3s ease-in-out;
  }
`;