import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'

export const ProdukContainer = styled.div`
  height: 640px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #284D84c1;
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    height: 1100px;
  }

  @media screen and (max-width: 480px) {
    height: 1300px;
  }
 `
export const ProdukWrapper = styled.div`
    max-width: 1000px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    grid-gap: 16px;
    padding: 0 50px;

    @media screen and (max-width: 1028px) {
      grid-template-columns: 1fr;
  }

    @media screen and (max-width: 786px) {
      grid-template-columns: 1fr;
      padding: 0 20px;
  }
`
export const ProdukCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px auto;
  padding:  30px;
  margin-right: 15px;
  box-shadow: 0 1px 10px #25252572;
  transition: all 0.2s ease-in-out;
  height: 360px;
  width: 340px;
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor:pointer;
    border-radius: 10px;
  }
`
export const ProdukIcon = styled.img`
  height: 160px;
  width: 200px;
  
  margin-bottom: 10px;

`
export const ProdukIcon2 = styled.img`
  height: 160px;
  width: 160px;
  
  margin-bottom: 10px;

`
export const ProdukH1 = styled.h1`
  margin-top: 20px;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 64px;
  line-height: 2;
  @media screen and (max-width: 480px) {
    font-size: 2rem;
    line-height: 2;
  }  
`
export const ProdukH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`
export const ProdukH3 = styled.h5`
  font-size: 15px;
  color: #284D84c1;
  letter-spacing: 1px;
  margin-bottom: 10px;
`

export const ProdukP = styled.p`
  font-size: 1rem;
  text-align: center;
  
`

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`
export const NavBtnLink = styled(LinkR)`
  margin-top: 15px;
  background: #284D80;
  white-space: nowrap;
  padding: 10px 22px;
  color: #fefefe;
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #5C87C3;
    color: #FEFEFE;
  }
`


