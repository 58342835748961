import React from "react";
import Carousel from "react-elastic-carousel";
import {ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesIcon2, ServicesH2,ServicesH3, ServicesP}  from './experienceElement';
import Sisforen from '../../images/experience/sisforen.png'
import Sisfolog from '../../images/experience/sisfolog.png'
import Petainduk from '../../images/experience/petainduk.png'
import Jmclick from '../../images/experience/jmclick.png'
import Brawijaya from '../../images/experience/brawijaya.png'
import Videowall from '../../images/experience/smart-wall.svg'
import Interaktive from '../../images/experience/interaction.png'
import Prisalah from '../../images/experience/automation.png'
import Item from "./item";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const Experience = () => {
    return (
        <ServicesContainer id='experience'>
            <ServicesH1>
                Experience
            </ServicesH1>
        <Carousel breakPoints={breakPoints}>

        <ServicesCard>
          <ServicesIcon src={Sisforen}/>
            <ServicesH2>SISFOREN</ServicesH2>
            <ServicesH3>2020</ServicesH3>
          <ServicesP>Sistem Informasi Perencanaan yang sudah di implementasikan dan saat ini sedang dalam tahap pengembangan tahap lanjut di TNI AD</ServicesP>
        </ServicesCard>
        
        <ServicesCard>
          <ServicesIcon2 src={Petainduk}/>
            <ServicesH2>PETA INDUK TNI AD</ServicesH2>
            <ServicesH3>2020</ServicesH3>
          <ServicesP>Sistem Informasi Peta Induk di Pusat Kendali Operasi (Puskodal) MABES TNI AD tahun 2020.</ServicesP>
                </ServicesCard>
                
        <ServicesCard>
          <ServicesIcon2 src={Prisalah}/>
            <ServicesH2>I-Perisalah</ServicesH2>
            <ServicesH3>2020</ServicesH3>
          <ServicesP>Sistem Informasi I-Prisalah di Satkomlek MABES TNI.</ServicesP>
        </ServicesCard>

        <ServicesCard>
          <ServicesIcon src={Videowall}/>
            <ServicesH2>VIDEO WALL RUANG RAPAT</ServicesH2>
            <ServicesH3>2020</ServicesH3>
          <ServicesP>Pengadaan Video Wall Ruang Rapat Srenaad.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Interaktive}/>
            <ServicesH2>PENGADAAN INTERACTIVE DISPLAY</ServicesH2>
            <ServicesH3>2021</ServicesH3>
          <ServicesP>Pengadaan Interactive Display di Dinas Informasi Pengolahan Data Angkatan Darat (DISINFOLAHTAD).</ServicesP>
        </ServicesCard>
                <ServicesCard>
        <ServicesIcon src={Sisfolog}/>
            <ServicesH2>SISFOLOG</ServicesH2>
            <ServicesH3>2021</ServicesH3>
          <ServicesP>Sistem Pengelolaan Asset Manajemen yang masih dalam tahap pengembangan di Mabes AD.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Jmclick}/>
            <ServicesH2>JM-CLICK</ServicesH2>
            <ServicesH3>2021</ServicesH3>
          <ServicesP>Pengembangan Sistem Informasi JM-Click pada bidang Human Capital Jasa Marga</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Brawijaya}/>
            <ServicesH2>BRAWIJAYA SIAGA</ServicesH2>
            <ServicesH3>2021</ServicesH3>
          <ServicesP>Pengembangan Sistem Informasi Brawijaya Siaga pada bidang perencanaan Kodam V Brawijaya.</ServicesP>
        </ServicesCard>
      
            </Carousel>
        </ServicesContainer>
    )
}

export default Experience;